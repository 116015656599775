body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


table {
  width: 100%;
  text-align: center;
}

th {
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
}

td {
  font-size: 1.2rem;
  text-align: center;
  vertical-align: middle;
}

label {
  margin-right: 5px;
  margin-bottom: 5px;
}

.poll-button {
  margin: 0;
}
.admin-page-stats {
  background-color: #F5F5F5;
  padding: 16px;
  text-align: center;
}
.book-table-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.book-table-summary-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.book-table-summary-item span:first-child {
  margin-right: 0.5rem;
  font-weight: bold;
}

.book-table-summary-item span:last-child {
  font-size: 1.2rem;
  font-weight: bold;
}

.ui.labeled.icon.button, .ui.labeled.icon.buttons .button {
  position: relative;
  padding-left: 3.1em !important;
  padding-right: 1em !important;
  font-size: 0.9em;
}

@media only screen and (max-width: 767px) {
  .book-table-container {
    overflow-x: auto;
  }

  table {
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .book-table-container {
    overflow-x: auto;
  }

  table {
    max-width: 100%;
  }

  td, th {
    font-size: 0.7rem;
  }
  .ui.green.label{
    font-size: 0.7rem;
  }
  .ui.red.label{
    font-size: 0.7rem;
  }
  .ui.labeled.icon.button, .ui.labeled.icon.buttons .button {
    position: relative;
    padding-left: 3.1em !important;
    padding-right: 1em !important;
    font-size: 1.2em;
  }


}

.book-table-summary {
  margin: 30px 0;
}

.book-table-summary-header {
  margin-bottom: 10px;
}

.book-table-summary-icon {
  margin-right: 10px;
}

.book-table-summary-alert {
  margin-bottom: 20px;
}

.book-table-summary-alert-header {
  margin-bottom: 10px;
}

.book-table-summary-alert-content {
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.Button {
  background-color: #2185d0;
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  border-radius: 0.3em;
  cursor: pointer;
}

.Button:hover {
  background-color: #1678c2;
}
